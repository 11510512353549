<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="
              d-flex
              align-items-center
              justify-content-start
              mb-1 mb-md-0
            "
          >
            <label>Show</label>
            <v-select
            data-testid="page-size"
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>SortBy</label>
            <v-select
              v-model="sort"
              style="width:200px"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="sortOptions"
              label="label"
              :reduce="(val) => val.id"
              multiple
              :clearable="true"
            />

          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
      data-testid="links-table"
        ref="refCourseListTable"
        class="position-relative"
        striped
        responsive
        :fields="tableColumns"
        primary-key="id"
        :items="rediractionList"
        show-empty
        empty-text="No matching records found"
      >
        <template #cell(online)="data">
          <span v-if="data.item.online == 0">Classic</span>
          <span v-else>Online</span>
        </template>

        <!-- Column: Status -->

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <a
            target="_blank"
            :href="data.item.to_link"
          >
            <feather-icon icon="LinkIcon" />
          </a>
          <a
            v-if="$can('delete', 'redirection')"
            role="button"
            @click="DeleteItem(data.item.id)"
          >
            <feather-icon
              icon="TrashIcon"
              :data-testid="`delete-item-${data.item.id}`"
            /></a>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCategories"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref, computed, watch, reactive,
} from '@vue/composition-api'
import Vue from 'vue'
import store from '@/store'
// import useCousresList from './useCousresList'
// import userStoreModule from '../userStoreModule'

export default {
  components: {
    // useCousresList,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    vSelect,
  },
  setup() {
    const rediractionList = ref([])
    const meta = reactive({})
    const perPageOptions = [25, 50, 100, 200]
    const perPage = ref(10)
    const refCourseListTable = ref(null)
    const totalCategories = ref(0)
    const sortOptions = [{ label: 'From Link', id: 'from_link' }, { label: 'To Link', id: 'to_link' }]
    const sort = ref('')
    const searchQuery = ref('')
    const currentPage = ref(1)
    const GetAllrediraction = (ctx, callback) => {
      const data = sort.value.toString().replace('[', '')
      store
        .dispatch('setting/getAllRediractionArabic', {
          'filter[search]': searchQuery.value,
          sort: data,
          per_page: perPage.value,
          page: currentPage.value,
        })
        .then(response => {
          //   console.log("rediractionList", response.data.data);
          // rediractionList.value=response.data.data
          rediractionList.value = response.data.data
          totalCategories.value = response.data.meta.total
        })
    }
    const refetchData = () => {
      refCourseListTable.value.refresh()
    }
    watch([currentPage, perPage, searchQuery, sort], () => {
      GetAllrediraction()
    })
  
    GetAllrediraction()

    const dataMeta = computed(() => {
      const localItemsCount = refCourseListTable.value
        ? refCourseListTable.value.localItems.length
        : 0
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalCategories.value,
      }
    })
    // GetAllrediraction()
    const DeleteItem = id => {
      Vue.swal({
        icon: 'warning',
        title: 'Are You Sure?',
        confirmButtonText: 'YES',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      }).then(result => {
        if (result.value) {
          store.dispatch('setting/DeleteLink', id).then(response => {
            GetAllrediraction()
            Vue.swal({
              title: 'Link Deleted ',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
        }
      })
    }
    const tableColumns = [
      { key: 'id', label: 'id' },
      { key: 'from_link', label: 'From Link' },
      {
        key: 'to_link',
        label: 'To Link',

      },

      { key: 'actions' },
    ]

    return {
      tableColumns,
      rediractionList,
      DeleteItem,
      meta,
      perPageOptions,
      totalCategories,
      currentPage,
      perPage,
      dataMeta,
      refCourseListTable,
      refetchData,
      GetAllrediraction,
      searchQuery,
      sortOptions,
      sort,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
